<template>
  <div class="dataM editworkCalendar">
    <div class="content">
      <div class="title">
        日历方案{{edit.id?'编辑':'新建'}}
      </div>
      <el-form ref="form" :model="form" label-position="left">
        <div class="workflex">
          <el-form-item label="">
            <el-radio :label="1" :value="1" v-model="form.isDefault" style="margin-left:10px">默认模板</el-radio>
          </el-form-item>
          <el-form-item label="有效期">
            <el-date-picker
              v-model="date"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width:100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              popper-class="dataM"
            >
          </el-date-picker>
          <div class="tip">
            * 如果选择的日历方案已过有效期，<br/>则默认按原方案继续顺延。
          </div>
          </el-form-item>
        </div>
        <div class="workflex">
          <el-form-item label="方案名称">
            <el-input v-model="form.workshiftSchemeName"></el-input>
          </el-form-item>
          <el-form-item label="执行部门">
            <el-input v-model="form.department"></el-input>
          </el-form-item>
        </div>
        <div class="workflex">
          <el-form-item label="工作制">
            <el-radio-group v-model="form.workweekType">
              <el-radio :label="5">5天</el-radio>
              <el-radio :label="6">6天</el-radio>
              <el-radio :label="7">7天</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="工作日">
            <el-checkbox-group v-model="form.weekdays">
              <el-checkbox v-for="item in weekdays" :key="item.label" :label="item.label" name="type">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        <el-form-item label="国家及地区假期">
          <el-select v-model="form.countryName">
            <el-option label="中国" value="中国"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班次">
          <el-table 
            :data="form.shiftInfos" 
            header-cell-class-name="tableHeader" 
            @selection-change="handleSelectionChange" 
            style="width: 100%"
          >
            <el-table-column type="selection" width="55"/>
            <el-table-column type="index" label="序号" width="100"/>
            <el-table-column prop="shiftName" label="班次名称"/>
            <el-table-column prop="startTime" label="开始时间">
              <template slot-scope="scope">
                <span>{{scope.row.showStartTime || scope.row.startTime}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="duration" label="时长">
              <template slot-scope="scope">
                {{scope.row.duration}}小时
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注"/>
          </el-table>
        </el-form-item>
        <div class="operation">
          <el-button type="primary" @click="dialogVisible = true">添加</el-button>
          <el-button @click="delClass">删除</el-button>
        </div>
      </el-form>
    </div>
    <div class="footer">
      <el-button @click="$router.push('/workCalendar')">取消</el-button>
      <el-button type="primary" @click="onSave">确认</el-button>
    </div>
    <!-- 添加班次弹窗 -->
    <el-dialog
      title="添加班次"
      :visible.sync="dialogVisible"
      custom-class="dataM"
      width="450px"
      :close-on-click-modal="false"
      >
      <div class="item">
        <div>班次名称</div>
        <el-input v-model="classes.shiftName"></el-input>
      </div>
      <div class="item">
        <div>时间</div>
          <div style="display:flex;align-items:center">
            <el-time-picker
            v-model="classes.startTime"
            placeholder="开始时间"
            style="width :100%"
            value-format="HH:mm"
            format="HH:mm"
            popper-class="dataM"
            :picker-options="{
              selectableRange: '00:00:00 - 23:59:59'
            }"
          /> 
          <!-- <span>至</span>
          <el-time-picker
            v-model="time.endTime"
            placeholder="结束时间"
            style="width :100%;margin-left:10px"
            value-format="HH:mm"
            format="HH:mm"
            popper-class="dataM"
            :picker-options="{
              selectableRange: '00:00:00 - 23:59:59'
            }"
          /> -->
          </div>
      </div>
      <div class="item">
        <div>时长</div>
        <el-input class="duration" v-model="classes.duration" clearable @input="output('classes','duration')"/>
      </div>
      <div class="item">
        <div>备注</div>
        <el-input type="textarea" v-model="classes.remark"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addClass">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getShiftDetail,addWorkshift,editWorkshift} from '@/api/data.js'
import {isComfirm2} from '@/utils/data.js'
import { mapState } from 'vuex'
export default {
  name:"editworkCalendar",
  data(){
    return{
      weekdays:Object.freeze([
        {
          label:1,
          name:'星期一'
        },{
          label:2,
          name:'星期二'
        },{
          label:3,
          name:'星期三'
        },{
          label:4,
          name:'星期四'
        },{
          label:5,
          name:'星期五'
        },{
          label:6,
          name:'星期六'
        },{
          label:7,
          name:'星期日'
        },
      ]),
      date:"",
      // time:{
      //   startTime:"",
      //   endTime:""
      // },
      edit:{},
      form:{
        "isDefault":0,
        "workshiftSchemeName": "",
        "department":"",
        "startDate": "",
        "endDate": "",
        "workweekType": "",
        "weekdays": [],
        "countryName": "中国",
        "shiftInfos":[],
      },
      dialogVisible:false,
      classes:{
        "shiftName": "",
        "startTime": "",
        "duration":"",
        "remark": "",
      },
      multipleSelection:[]
    }
  },
  computed:{
    ...mapState(['apsRoleNumber'])
  },
  watch:{
    dialogVisible(){
      this.resetClass()
    },
    'form.workweekType'(val){
      console.log('工作制',val)
      switch(val){
        case 5:
          this.form.weekdays = [1,2,3,4,5]
          break
        case 6:
          this.form.weekdays = [1,2,3,4,5,6]
          break
        case 7:
          this.form.weekdays = [1,2,3,4,5,6,7]
      }
    }
  },
  activated(){
    // Object.assign(this.form,this.$options.data().form)
    // this.date = '';
    Object.assign(this.$data,this.$options.data())
    if(this.$route.query.id){
      this.edit.shiftId = Number(this.$route.query.shiftId)
      this.edit.id = Number(this.$route.query.id)
      this.getDetail()
    }
  },
  methods:{
    //获取工作日历详情
    getDetail(){
      getShiftDetail(this.edit.shiftId).then(res=>{
        if(res.code === 0){
          let {isDefault,workshiftSchemeName,startDate,endDate,workweekType,weekdayList,countryName,shiftInfos,department} = res.data
          this.form = {
            isDefault,
            workshiftSchemeName,
            startDate,
            endDate,
            workweekType,
            weekdays:weekdayList,
            countryName,
            shiftInfos,
            department
          }
          this.date = [startDate,endDate]
        }
      })
    },
    //重置班次弹窗
    resetClass(){
      this.classes = {
        "shiftName": "",
        "startTime": "",
        "duration":"",
        "remark": "",
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log('this.multipleSelection',this.multipleSelection)
    },
    //删除班次
    delClass(){
      let select = this.multipleSelection.map(v=>v.shiftName)
      this.form.shiftInfos = this.form.shiftInfos.filter(v=>!select.includes(v.shiftName))
    },
    //添加班次
    addClass(){
      let {shiftName,duration,startTime} = this.classes;
      if(!startTime || !duration || !shiftName){
        this.$message({
          message:'请补充完整信息',
          showClose: true,
          duration: 0,
          type:'warning'
        })
        return
      }
      // this.classes.startTime = this.time?.startTime;
      // this.classes.endTime = this.time?.endTime;
      // //判断是否有重复时间
      // let arr = [...this.form.shiftInfos,this.classes]
      // const flag = areTimeRangesConflicting(arr)
      // if (flag) {
      //   return this.$message.warning('班次时间冲突')
      // }
      this.form.shiftInfos.push(this.classes);
      this.dialogVisible = false
    },
    //提交
    onSave(){
      this.form.startDate = this.date?.[0];
      this.form.endDate = this.date?.[1];
      let Api = this.edit.shiftId ? editWorkshift : addWorkshift
      let params = {
        ...this.form,
        ...this.edit
      }
      if(!params.startDate){
        return this.$message({
          message:'请输入有效期',
          showClose: true,
          duration: 0,
          type:'warning'
        })
      }
      if(!params.endDate){
        return this.$message({
          message:'请输入有效期',
          showClose: true,
          duration: 0,
          type:'warning'
        })
      }
      if(!params.workshiftSchemeName){
        return this.$message({
          message:'请输入方案名称',
          showClose: true,
          duration: 0,
          type:'warning'
        })
      }
      // if(!params.department){
      //   return this.$message.warning('请输入执行部门')
      // }
      if(!params.workweekType){
        return this.$message({
          message:'请输入工作制',
          showClose: true,
          duration: 0,
          type:'warning'
        })
      }
      if(!params.weekdays.length){
        return this.$message({
          message:'请输入工作日',
          showClose: true,
          duration: 0,
          type:'warning'
        })
      }
      if(params.weekdays.length != params.workweekType){
        return this.$message({
          message:'工作制和工作日不匹配',
          showClose: true,
          duration: 0,
          type:'warning'
        })
      }
      if(!this.form.shiftInfos.length){
        return this.$message({
          message:'请输入班次信息',
          showClose: true,
          duration: 0,
          type:'warning'
        })
      }
      // const flag = areTimeRangesConflicting([...this.form.shiftInfos])
      // if (flag) {
      //   return this.$message.warning('班次时间冲突')
      // }
      Api(params).then(res=>{
        if(res.code === 0){
          if(this.edit.shiftId && (this.apsRoleNumber ==1 || this.apsRoleNumber ==2)){
            isComfirm2({
              title: '是否去更新计划？',
              iconClass: 'el-icon-warning',
              customClass: 'errorMessage',
              confirmButtonText:'确认'
              },()=>{
                this.$router.push('/ganttProduction')
              },()=>{
                this.$router.push('/workCalendar')
              }
            )
          }else{
            this.$message.success('操作成功')
            this.$router.push('/workCalendar')
          }
        }else{
          this.$message({
            message:res.msg || '接口失败',
            showClose: true,
            duration: 0,
            type:'error'
          })
        }
      }).catch(()=>{
        this.$message({
          message:'接口失败',
          showClose: true,
          duration: 0,
          type:'error'
        })
      })
    },
    output(key1,key2){
      // 只能输入数字
      this.$set(this[key1],key2,this[key1][key2].replace(/[^0-9.]/g, ''))
    },
  }
}
</script>

<style lang="scss">
.editworkCalendar{
  min-height: calc(100vh - 160px);
  text-align: left;
  padding: 18px 18px 50px;
  color: #E8E8E8;
  .workflex{
    display:flex;
    align-items:center;
    div:first-child{
      min-width: 310px;
      margin-right: 50px;
    }
    .tip{
      color: #8A8A8A;
      font-size: 14px;
      line-height: 20px;
      width: 450px;
    }
  }
  .content{
    background: #232323;
    padding: 25px 35px;
    .tableHeader{
      background: #2C2C2C;
      color: #B9B9B9;
    }
    .title{
      font-size: 20px;
      margin-bottom: 20px;
      color: #FFFFFF;
    }
    .el-form-item{
      display: flex;
      flex-direction: column;
      .el-form-item__content{
        display: flex;
        align-items: center;
      }
    }
  }
  .footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #232323;
    text-align: right;
    padding: 10px 28px;
    z-index: 9;
    }
    .footer,.operation{
      .el-button{
        width: 90px;
        color: #FFFFFF;
        border: 1px solid rgba(255,255,255,0.2);
        border-radius: 6px;
      }
    }
}
</style>
<style scoped lang="scss">
::v-deep{
  .item{
    .duration{
      position: relative;
      &:after{
        content: "小时";
        position: absolute;
        display: block;
        clear: both;
        right: 10px;
        top: 6px;
        color: #7F7F7F;
        pointer-events: none;
      }
    }
  }
}
</style>